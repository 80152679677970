import { Injectable } from '@angular/core';
import { Initialable } from './app-init.service';
import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { NetworkStatusService } from './network-status.service';
import { environment } from './../../environments/environment';
import { catchError, Observable, of, switchMap } from 'rxjs';

declare const zE: any;

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init4', initializer: `onInit` })
@EasyDebugDecorator
export class ZendeskService {
  private _defaultConversationTags: string[] = [];

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private networkService: NetworkStatusService
  ) {
  }

  async onInit() {
    // this.initZendeskMessaging();
    return 'ZendeskService done';
  }

  private initZendeskMessaging(): void {
    this.platform.ready().then(async () => {
      await this.addZendeskMessagingScript();
    }).then(() => {
      this.createZendeskMessagingLauncher();
    }).then(() => {
      this.setZendeskMessagingOnClose();
    }).then(() => {
      this.setZendeskMessagingLocale();
    }).finally(() => {
      this.setDefaultConversationTags();
    }).catch((error) => {
      console.log("ZendeskService initZendeskMessaging error", error);
    });
  }

  private addZendeskMessagingScript(): Promise<void> {
    return new Promise((resolve) => {
      const zendeskMessagingScript = document.createElement('script');
      zendeskMessagingScript.id = 'ze-snippet';
      zendeskMessagingScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${environment.zendesk.zendeskMessagingWidgetKey}`;
      zendeskMessagingScript.addEventListener('load', (event) => {
        resolve();
      });
  
      document.body.appendChild(zendeskMessagingScript);
    })
  }

  private createZendeskMessagingLauncher(): void {
    const zendeskMessagingLauncher = document.createElement('div');
    zendeskMessagingLauncher.id = 'conversation-badge';
    zendeskMessagingLauncher.onclick = () => this.openZendeskMessaging();
    zendeskMessagingLauncher.style.opacity = '0';
    zendeskMessagingLauncher.style.visibility = 'hidden';
    zendeskMessagingLauncher.style.background = 'var(--color-orange-500)';
    zendeskMessagingLauncher.style.width = '64px';
    zendeskMessagingLauncher.style.height = '64px';
    zendeskMessagingLauncher.style.borderRadius = '50%';
    zendeskMessagingLauncher.style.display = 'flex';
    zendeskMessagingLauncher.style.justifyContent = 'center';
    zendeskMessagingLauncher.style.alignItems = 'center';
    zendeskMessagingLauncher.style.cursor = 'pointer';
    zendeskMessagingLauncher.style.position = 'fixed';
    zendeskMessagingLauncher.style.bottom = '30px';
    zendeskMessagingLauncher.style.right = '30px';
    zendeskMessagingLauncher.style.zIndex = '999999';
    zendeskMessagingLauncher.style.transition = 'opacity .5s ease-in';

    const zendeskMessagingLauncherIcon = document.createElement('img');
    zendeskMessagingLauncherIcon.src = 'assets/2022/icons/chat-off-black.svg';

    zendeskMessagingLauncher.appendChild(zendeskMessagingLauncherIcon);

    document.body.appendChild(zendeskMessagingLauncher);
  }

  private setZendeskMessagingLocale(locale: string = 'fr'): void {
    zE('messenger:set', 'locale', locale);
  }

  private async loginZendeskMessagingUser(): Promise<void> {
    const token = await this.fetchZendeskMessagingUserJWT().toPromise();
    zE('messenger', 'loginUser',
      (callback) => {
        callback(token);
      },
      (error) => {
        if (error) {
          console.log("ZendeskService loginZendeskMessagingUser error", error);
        }
      }
    );
  }

  public fetchZendeskMessagingUserJWT(): Observable<any> {
    if (this.networkService?.isOffline()) {
      return of("");
    }

    const url = `${environment.token_auth_config.apiBase}/v3/auth/zendesk`;
    
    return this.http.get(url).pipe(
      switchMap(
        async (data: {token: string}) => {
          return data.token;
      }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: "ZendeskService ZendeskJWT Service failed", errorOriginal: err};
        }
      )
    );
  }

  private logoutZendeskMessagingUser(): void {
    zE('messenger', 'logoutUser');
  }

  public showZendeskMessagingLauncher(): void {
    const zendeskLauncher = document.getElementById('conversation-badge');
    zendeskLauncher.style.opacity = '1';
    zendeskLauncher.style.visibility = 'visible';
  }

  public hideZendeskMessagingLauncher(): void {
    this.closeZendeskMessaging();
    const zendeskLauncher = document.getElementById('conversation-badge');
    zendeskLauncher.style.opacity = '0';
    zendeskLauncher.style.visibility = 'hidden';
  }

  public openZendeskMessaging(conversationTags: string[] = []): void {
    this.loginZendeskMessagingUser().then(() => {
      zE('messenger:set', 'conversationTags', [...this._defaultConversationTags, ...conversationTags]);
    }).finally(() => {
      zE('messenger', 'open');
    });
  }

  public closeZendeskMessaging(): void {
    zE('messenger', 'close');
  }

  private setZendeskMessagingOnClose(): void {
    zE('messenger:on', 'close',
      () => {
        this.logoutZendeskMessagingUser();
      }
    );
  }

  private setDefaultConversationTags(): void {
    let platformTag = 'web_app_student';

    if (this.platform.is('cordova')) {
      platformTag = (this.platform.is('ios')) ? 'ios_app_student' : 'android_app_student';
    }

    this._defaultConversationTags.push(platformTag);
  }
}
